/* Chatbot.css */
.chatbot-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .chat-messages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    width: 60%;
    height: 60vh;
    overflow-y: auto;
  }
  
  .bot-message {
    background-color: #f0f0f0;
    padding: 8px;
    border-radius: 10px;
    margin: 4px;
    width: 60%;
  }
  
  .user-message {
    background-color: #e0e0e0;
    padding: 8px;
    border-radius: 10px;
    margin: 4px;
    align-self: flex-end;
  }
  
  .input-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
  
  .input-field {
    padding: 6px;
    /* margin-top: 8px;/ */
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .bot-prompt {
    margin-top: 20px;
  }
  
  .got-it-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .bot-countdown {
    margin-top: 20px;
  }
  